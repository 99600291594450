import React, { Fragment} from "react"

const SubTitle = (props) => {
    return (
        <Fragment>
            <div className="cv-subtitle">{props.title}</div>
            <hr className="cv-line"/>
        </Fragment>
    )
}

export default SubTitle