import { useEffect, useLayoutEffect, useRef } from "react"
import './loader.css'
import { gsap, Circ } from 'gsap'

import useTimeout from "../../hooks/useTimeout"

interface Props {
    isLoading: boolean
    onComplete: () => void
}

const Loader = ({ isLoading, onComplete: done }: Props) => {
    const MAX = 0.9
    const MIN = 0.6

    const bolt = useRef(null)
    const bulb1 = useRef(null)
    const bulb2 = useRef(null)

    useEffect(() => {
        gsap.set(bolt.current, { autoAlpha: 0 })
        gsap.set(bulb1.current, { autoAlpha: 0 })
        gsap.set(bulb2.current, { autoAlpha: 0 })
    }, [])

    useEffect(() => {
        if (isLoading) {
            gsap.fromTo(bolt.current, { rotate: 0, autoAlpha: 0 }, {
                rotate: 720, autoAlpha: MIN, duration: 1, delay: .5, transformOrigin: "50% 59%", ease: Circ.easeInOut, onComplete: () => {
                    gsap.fromTo(bolt.current, { autoAlpha: MIN }, { autoAlpha: MAX, duration: .05, repeat: -1, yoyo: true })

                    gsap.fromTo(bulb1.current, { autoAlpha: 0 }, {
                        autoAlpha: MAX, duration: 1
                    })

                    gsap.fromTo(bulb2.current, { autoAlpha: 0 }, {
                        autoAlpha: MAX, duration: .5, delay: .5, repeat: -1, yoyo: true
                    })
                }
            })
        } else {
            gsap.killTweensOf(bolt.current)
            gsap.to(bulb1.current, { autoAlpha: 0, duration: .3 })
            gsap.set(bulb2.current, { autoAlpha: 0 })
            gsap.to(bolt.current, { autoAlpha: 0, rotate: 0, delay: .3, duration: 1, ease: Circ.easeInOut, onComplete: () => done() })
        }

        return (() => {
            gsap.killTweensOf(bolt.current)
            gsap.killTweensOf(bulb1.current)
            gsap.killTweensOf(bulb2.current)
        })

    }, [isLoading])

    return (
        <div style={{ position: "absolute", zIndex: 9 }}>
            <div id="loader">
                <div className="bolt" ref={bolt} />
                <div className="blub1" ref={bulb1} />
                <div className="blub2" ref={bulb2} />
            </div>
        </div>
    )
}

export default Loader