import React from "react"

const Title = () => {
    return (
        <div>
            <div className="cv-title">Curriculum Vitae</div>
            <div className="cv-name">Michael Abernethy</div>
        </div>
    )
}

export default Title