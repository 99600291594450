import { useEffect, useState } from 'react'
import { IProject } from './../types/projectTypes'

const galleryImages = [
    'https://picsum.photos/id/120/1920/1080/',
    'https://picsum.photos/id/121/1920/1080/',
    'https://picsum.photos/id/122/1920/1080/',
    'https://picsum.photos/id/123/1920/1080/',
    'https://picsum.photos/id/124/1920/1080/',
    'https://picsum.photos/id/125/1920/1080/',
    'https://picsum.photos/id/126/1920/1080/',
    'https://picsum.photos/id/127/1920/1080/',
    'https://picsum.photos/id/128/1920/1080/',
    'https://picsum.photos/id/129/1920/1080/',
    'https://picsum.photos/id/130/1920/1080/'
]


const Project = ({ id, title, backgroundColor, extraClass }: IProject) => {
    const [images, setImages] = useState<string[]>()

    useEffect(() => {
        const shuffled = galleryImages
            .map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)

        setImages(shuffled)
    }, [])

    return <section className={`panel ${title?.toLowerCase()} ${extraClass || ""}`} style={{ backgroundColor: backgroundColor }}>

        <div className='galleryContainer'>
            {
                images && images.map((src) => {
                    return <div key={`${src}-${id}`} style={{ backgroundImage: `url(${src})` }} className={`galleryImage noselect`} />
                })
            }
        </div>

        <h1>{title}</h1>

    </section>
}

export default Project