import React, { Fragment, useState, useEffect } from "react"
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'

import './cv.css';
import Title from './Title'
import SubTitle from './SubTitle'

const CV = () => {
    const { search } = useLocation()
    const [ok, setOk] = useState(false)

    useEffect(() => {
        const values = queryString.parse(search)
        if(values.auth !== ""){
            if(values.auth === process.env.REACT_APP_AUTH_KEY){
                //validate with api call
                setOk(true)
            }
        }
    }, [search])

    return (
        <Fragment>
                {ok ?
                    <Fragment>
                        <div className="logo"/>
                        <Title/>
                        <SubTitle title="Introduction" />
                        <div>
                            <p>I have been working as an Interactive developer for about 10 years. I lot of the work done was for museums and events.</p>
                        </div>
                        <SubTitle title="Things I've done" />
                    </Fragment>
                   : <div>Oh no, that's not right</div>
                }
                  </Fragment>
            
    )
}

export default CV