const Home = () => {
    return <section className="panel first">
        {
            <>
                <div style={{ display: "flex", justifyContent: "left", alignItems: "start", width: "100vw", position: "relative", fontSize: "0", border: "solid 2vw black", padding: "2vw", margin: "5vw" }}>
                    <h1 style={{ position: "relative", fontSize: "8vw" }}>Creative Developer</h1>
                    <h6 style={{ position: "absolute", fontSize: "3vw", right: "2vw", top: "17vw" }}>Mike Abernethy</h6>
                </div>

            </>
        }

    </section >
}

export default Home