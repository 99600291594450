import React from 'react'
import { Route, Switch } from "react-router-dom"
import './App.css';
import CV from './components/cv/CV'
import Logo from './components/logo/Logo'
import Welcome from './components/Welcome'
require('dotenv').config()

function App() {
  return (
    <>
      <main>
        <Switch>
          <Route exact path="/">
            <Welcome />
          </Route>
          <Route path="/cv">
            <CV />
          </Route>
        </Switch>
      </main>

      <header>
        <Logo />
      </header>
    </>
  );
}

export default App;
