import { useEffect, useState, useRef } from "react"
import './logo.css'
import { gsap } from 'gsap'
import { Draggable } from "gsap/Draggable";

import useTimeout from "../../hooks/useTimeout"

const Logo = () => {
    const MAX = 0.9
    const MIN = 0.1

    const bolt = useRef(null)
    const logo = useRef(null)

    const tl = useRef(gsap.timeline())

    useEffect(() => {
        Draggable.create('#logo')
        gsap.set(logo.current, { autoAlpha: 0 })
        gsap.set(bolt.current, { autoAlpha: 0 })
    }, [])

    useTimeout(() => {
        gsap.fromTo(bolt.current, { autoAlpha: .5 }, { autoAlpha: MAX, duration: .05, repeat: -1, yoyo: true })
        tl.current.fromTo(bolt.current, { y: -500 }, {
            y: 0, duration: 1, onComplete: () => {
                tl.current.fromTo(logo.current, { autoAlpha: 0 }, { autoAlpha: MAX, duration: .1, delay: .5, repeat: 4, yoyo: true })
            }
        })
    }, 1000)

    useTimeout(() => {
        tl.current.kill()
    }, 5000)

    return (
        <div id="logo">
            <div className="bolt" ref={bolt} />
            <div className="ma" ref={logo} />
        </div>
    )
}

export default Logo