import { useState, useEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from "gsap/Draggable";
import axios from "axios"
import Home from './Home'
import Loader from './loader/Loader'
import delay from './../helpers/delay'
import Project from './Project'
import { IProject } from './../types/projectTypes'

import getApiUrl from "../helpers/getApiUrl"

const Welcome = () => {

    const [isLoading, setIsLoading] = useState(true)
    const [loaderComplete, setLoaderComplete] = useState(false)
    const [projects, setProjects] = useState<IProject[]>()
    const [url] = useState(getApiUrl())

    gsap.registerPlugin(ScrollTrigger, Draggable)

    ScrollTrigger.defaults({
        toggleActions: "restart pause resume pause",
        scroller: ".container"
    })

    useEffect(() => {
        const panels = document.querySelectorAll("#root main section.panel")

        if (panels?.length <= 1) return
        const sections = gsap.utils.toArray(panels)

        ScrollTrigger.create({
            trigger: '.first',
            start: 'top top',
            endTrigger: '.last',
            end: 'bottom bottom',

            snap: {
                snapTo: 1 / (sections.length - 1),
                duration: { min: 0.25, max: 0.75 }, 
                delay: 0.125,
                ease: "power1.inOut"
            }
        })
    }, [loaderComplete])

    useEffect(() => {
        const start = Date.now()
        axios.get(`${url}/project`)
            .then(response => setProjects(response.data.data))
            .then(() => delay(Math.max(5000 - (Date.now() - start), 0)))
            .catch(console.error)
            .finally(() => setIsLoading(false))
    }, [url])

    const onLoaderComplete = () => setLoaderComplete(true)

    return (
        <>
            {!loaderComplete && <Loader isLoading={isLoading} onComplete={onLoaderComplete} />}

            {loaderComplete && <div className="container">
                <Home />

                {projects && projects.map((e, i, arr) => {
                    let extraClass: undefined | string
            
                    if (i === arr.length - 1) extraClass = "last"
                    
                    return <Project key={e.title} {...e} extraClass={extraClass} />

                })}
            </div>}
        </>
    )
}

export default Welcome